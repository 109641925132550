import React from 'react';
import "./openhours.css";

import location from "../../../images/location.png";
import spicyimg from "../../../images/spice.png";

const openingHoursData = [
    { day: "Hétfő", hours: "7:00-21:00" },
    { day: "Kedd", hours: "7:00-21:00" },
    { day: "Szerda", hours: "7:00-21:00" },
    { day: "Csütörtök", hours: "7:00-21:00" },
    { day: "Péntek", hours: "7:00-22:00" },
    { day: "Szombat", hours: "11:00-22:00" },
    { day: "Vasárnap", hours: "11:00-15:00" },
  ];
  
  const OpeningHours = () => (
    <div className="opening-hours" id="open-hours">
      <div className="hours-list">
        <div className="weekdays">
          {openingHoursData.map((item, index) => (
            <div key={index} className="weekday">
              {item.day}:
            </div>
          ))}
        </div>
        <div className="hours">
          {openingHoursData.map((item, index) => (
            <div key={index} className={`hour ${item.hours === "ZÁRVA" ? "closed" : ""}`}>
              {item.hours}
            </div>
          ))}
        </div>
      </div>
      <div className="address">
        <img src={location} alt="Location icon" className="location-icon" />
        <div className="address-text">7030 Paks Vasút utca 1</div>
      </div>
    </div>
  );
  
  const HolidayNotice = () => (
    <div className="holiday-notice">
      <div className="holiday-title">Niytvatartás 10.28 - 11.01 között</div>
      <div className="holiday-text">
        Kedves vásárlóink! Csütörtökön (Okt. 31) csak büfénk lesz nyitva (szendvics, kávé, üdítő), majd péntektől vasárnapig <span className="closed-text">ZÁRVA</span> tartunk. Köszönjük a megértést!
      
      </div>
    </div>
  );
  
  function OpenHours() {
    return (
      <>
        <div className="open-header" id='scroll-openhours'>
            <h1>Hol és mikor találsz meg minket?</h1>
            <img className="spiceimg" src={spicyimg} alt="spice_icon" loading='lazy'/>
        </div>
        <main className="container">
          <div className="content">
            <div className="image-column-map">
              {/*<img src="https://cdn.builder.io/api/v1/ima
              ge/assets/TEMP/327f4b3e7b4589148aa3a9a7f3fd3db12b49ab5fd487d1bda467973d7df3ae66?apiKey=018341023e7f4b7f9bb3
    65ad3489d099&" alt="Descriptive alt text for the image" className="main-image" />*/}
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2740.971947226407!2d18.847988376776804!3d46.6075573564299!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47425d589f55e0d3%3A0x8f8e8ff24e4ab7e4!2zUGFrcyB0ZXJhc3ogYsO8ZsOp!5e0!3m2!1shu!2shu!4v1712850606512!5m2!1shu!2shu" 
              width="100%" height="100%" style={{border:1}}
              allowFullScreen={false} loading="lazy" 
              referrerPolicy="no-referrer-when-downgrade">
              
              </iframe>
            </div>
            <div className="info-column">
            {/*HOLIDAY NOTICE HERE*/}
           
              <section className="opening-hours-section">
                <h2 className="section-title">Nyitvatartás</h2>
                <OpeningHours />
              </section>
            </div>
          </div>
        </main>

      </>
    );
  }
  
  export default OpenHours;